import { useEffect, useRef, useState } from "react";
import "./sudoku";
import Cors from "cors";
import Router from "next/router";
import qs from "qs";
import { colors, LIGHT } from "./theme";

export const defineRow = (index) => {
  let row;
  for (let i = 1; i <= 9; i++) {
    if (index < i * 9 && index >= (i - 1) * 9) {
      row = i;
    }
  }
  return row;
};
export const defineCol = (index) => {
  let column;
  for (let i = 1; i <= 9; i++) {
    for (let col = 0; col < 9; col++) {
      if (index === col * 9 + (i - 1)) {
        column = i;
      }
    }
  }
  return column;
};
export const defineBox = (row, col) => {
  let box;
  if (row <= 3) {
    if (col <= 3) {
      box = 1;
    } else if (col <= 6) {
      box = 2;
    } else if (col <= 9) {
      box = 3;
    }
  } else if (row <= 6) {
    if (col <= 3) {
      box = 4;
    } else if (col <= 6) {
      box = 5;
    } else if (col <= 9) {
      box = 6;
    }
  } else if (row <= 9) {
    if (col <= 3) {
      box = 7;
    } else if (col <= 6) {
      box = 8;
    } else if (col <= 9) {
      box = 9;
    }
  }
  return box;
};

export function uniqueid() {
  // always start with a letter (for DOM friendlyness)
  let idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
  do {
    // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
    const ascicode = Math.floor(Math.random() * 42 + 48);
    if (ascicode < 58 || ascicode > 64) {
      // exclude all chars between : (58) and @ (64)
      idstr += String.fromCharCode(ascicode);
    }
  } while (idstr.length < 32);

  return idstr;
}

export const DEFAULT_NUMBER = "DEFAULT_NUMBER";
export const ENTERED_NUMBER = "ENTERED_NUMBER";

export const INCORRECT = "INCORRECT";
export const HAS_PROBLEM = "HAS_PROBLEM";

export const SELECTED_HIGHLIGHTED = "SELECTED_HIGHLIGHTED";
export const ROW_COLUMN_BOX_HIGHLIGHTED = "ROW_COLUMN_BOX_HIGHLIGHTED";
export const SAME_NUMBER_HIGHLIGHTED = "SAME_NUMBER_HIGHLIGHTED";

const CELL_TYPES = (theme_mode = LIGHT) => ({
  [INCORRECT]: {
    priority: 100,
    styles: {
      color: colors[theme_mode][INCORRECT],
    },
  },
  [SELECTED_HIGHLIGHTED]: {
    priority: 99,
    styles: {
      background: colors[theme_mode][SELECTED_HIGHLIGHTED],
    },
  },
  [ENTERED_NUMBER]: {
    priority: 99,
    styles: {
      color: colors[theme_mode][ENTERED_NUMBER],
    },
  },
  [HAS_PROBLEM]: {
    priority: 98,
    styles: {
      background: colors[theme_mode][HAS_PROBLEM],
    },
  },
  [ROW_COLUMN_BOX_HIGHLIGHTED]: {
    priority: 97,
    styles: {
      background: colors[theme_mode][ROW_COLUMN_BOX_HIGHLIGHTED],
    },
  },
  [SAME_NUMBER_HIGHLIGHTED]: {
    priority: 97,
    styles: {
      background: colors[theme_mode][SAME_NUMBER_HIGHLIGHTED],
    },
  },
  [DEFAULT_NUMBER]: {
    priority: 96,
    styles: {
      color: colors[theme_mode][DEFAULT_NUMBER],
    },
  },
});
export const defineListOfStyleTypesByPriority = (
  cell,
  selectedCell,
  autoCheckMistakes
) => {
  const { value, default: _default, has_problem, incorrect, index } = cell;
  const types = [DEFAULT_NUMBER];
  const column = defineCol(index);
  const row = defineRow(index);
  const box = defineBox(row, column);
  const selectedCellColumn = defineCol(selectedCell?.index);
  const selectedCellRow = defineRow(selectedCell?.index);
  const selectedCellBox = defineBox(selectedCellRow, selectedCellColumn);
  if (selectedCell?.index === index) {
    types.push(SELECTED_HIGHLIGHTED);
  }
  if (!_default) {
    types.push(ENTERED_NUMBER);
  }
  if (value && selectedCell?.value === value) {
    types.push(SAME_NUMBER_HIGHLIGHTED);
  }
  if (
    column === selectedCellColumn ||
    row === selectedCellRow ||
    box === selectedCellBox
  ) {
    types.push(ROW_COLUMN_BOX_HIGHLIGHTED);
  }
  if (has_problem) {
    types.push(HAS_PROBLEM);
  }
  if (incorrect && autoCheckMistakes) {
    types.push(INCORRECT);
  }

  return types.sort(
    (a, b) => CELL_TYPES()[a].priority - CELL_TYPES()[b].priority
  );
};
export const implementStyles = (listOfTypesName, theme_mode) => {
  return listOfTypesName.reduce((result, type) => {
    const _result = { ...result, ...CELL_TYPES(theme_mode)[type].styles };
    return _result;
  }, {});
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function useOuterClick(callback) {
  const callbackRef = useRef(); // initialize mutable callback ref
  const innerRef = useRef(); // returned to client, who sets the "border" element

  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => {
    callbackRef.current = callback;
  });
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}

export function timerFormatter(timer) {
  const pureHour = Math.floor(timer / (60 * 60));
  const hour = pureHour
    ? pureHour >= 10
      ? `${pureHour}:`
      : `0${pureHour}:`
    : "";
  const pureMinute = Math.floor((timer % (60 * 60)) / 60);
  const minute = pureMinute >= 10 ? `${pureMinute}:` : `0${pureMinute}:`;
  const pureSeconds = Math.floor((timer % (60 * 60)) % 60);
  const seconds = pureSeconds >= 10 ? `${pureSeconds}` : `0${pureSeconds}`;

  return `${hour}${minute}${seconds}`;
}

// Initializing the cors middleware
export const cors = Cors({
  methods: ["GET", "HEAD", "POST"],
});

// Helper method to wait for a middleware to execute before continuing
// And to throw an error when an error happens in a middleware
export function runMiddleware(req, res, fn) {
  return new Promise((resolve, reject) => {
    fn(req, res, (result) => {
      if (result instanceof Error) {
        return reject(result);
      }

      return resolve(result);
    });
  });
}

export const generateBoard = (difficulty) =>
  new Promise((res, rej) => {
    setTimeout(() => {
      const sudoku = (
        typeof window !== "undefined" ? window : global
      ).fn.sudokuJS();
      sudoku.clearBoard();
      sudoku.generateBoard(difficulty || "easy");
      const board = sudoku.getBoard();
      res(board.map((cell) => cell.val || undefined));
    }, 500);
  });
export const solveBoard = (_board) =>
  new Promise((res, rej) => {
    setTimeout(() => {
      const sudoku = global.fn.sudokuJS({ board: _board });
      sudoku.solveAll();
      const solvedBoard = sudoku.getBoard();
      res(solvedBoard.map((cell) => cell.val || undefined));
    }, 300);
  });
export const analyseBoard = (_board) =>
  new Promise((res, rej) => {
    setTimeout(() => {
      try {
        const sudoku = global.fn.sudokuJS({ board: _board });

        const analysis = sudoku.analyzeBoard();
        res(analysis);
      } catch (e) {
        console.log(e);
      }
    }, 300);
  });
export const nextCellToBeSolved = (_board) =>
  new Promise((res, rej) => {
    setTimeout(() => {
      const sudoku = global.fn.sudokuJS({
        board: JSON.parse(JSON.stringify(_board)),
      });
      sudoku.solveStep();
      const solvedBoard = sudoku.getBoard();
      let nextCellIndex = _board.findIndex(
        (cell, index) => cell === null && solvedBoard[index].val
      );
      res(nextCellIndex);
    }, 300);
  });

// export function getStrapiURL(path = "") {
//   return `${
//     process.env.NEXT_PUBLIC_STRAPI_API_URL ||
//     "https://braglord-sudoku-blog.herokuapp.com"
//   }${path}`;
// }

// Helper to make GET requests to Strapi
// export async function fetchAPI(path) {
//   const requestUrl = getStrapiURL(path);
//   const response = await fetch(requestUrl);
//   const data = await response.json();
//   return data;
// }

export function isTWA() {
  return typeof window === "undefined" || typeof document === "undefined"
    ? false
    : window.matchMedia("(display-mode: standalone)").matches ||
        document.referrer == "android-app://com.braglord.sudoku.twa";
}

export function pushParamsToUrl(..._params) {
  let serializedParams = {};
  const baseUrl = Router.asPath.split(/\?/)[0].split("#")[0];

  _params.forEach((p) => {
    if (typeof p === "string") {
      serializedParams[p] = true;
    } else if (typeof p === "object") {
      serializedParams = { ...serializedParams, ...p };
    }
  });
  let params = qs.parse(Router.asPath.split(/\?/)[1], {
    ignoreQueryPrefix: true,
  });
  const searchParams = { ...params, ...serializedParams };
  if (Object.keys(searchParams).length)
    Router.push(
      `${baseUrl}?${qs.stringify(searchParams, {
        skipNulls: true,
        encodeValuesOnly: true,
      })}`,
      null,
      { scroll: false, shallow: true }
    );
  else Router.push(Router.asPath, Router.asPath, { scroll: false });
}

export function removeParamsFromUrl(param) {
  const baseUrl = Router.asPath.split(/\?/)[0].split("#")[0];
  let params = qs.parse(Router.asPath.split(/\?/)[1], {
    ignoreQueryPrefix: true,
  });
  if (param) {
    if (params[param]) {
      delete params[param];
    }
  } else {
    params = {};
  }

  if (Object.keys(params).length) {
    Router.push(
      `${baseUrl}?${qs.stringify(params, {
        skipNulls: true,
        encodeValuesOnly: true,
      })}`,
      null,
      { scroll: false, shallow: true }
    );
  } else Router.push(baseUrl, null, { scroll: false, shallow: true });
}

import { createContext, useEffect, useState } from "react";

export const GEOContext = createContext();
export function GEOProvider(props) {
  const [countryCode, setCountryCode] = useState(null);
  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((data) => {
        setCountryCode(data.countryCode);
      });
  }, []);
  return (
    <GEOContext.Provider value={{ countryCode, setCountryCode }}>
      {props.children}
    </GEOContext.Provider>
  );
}

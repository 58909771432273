import "../styles/globals.scss";
import "../styles/bootstrap.min.scss";
import "../utils/sudoku";
import SnackbarProvider from "react-simple-snackbar";
import { ThemeProvider } from "../utils/theme";
import { useEffect } from "react";
import { GEOProvider } from "../utils/geolocation";
// import { isTWA } from "../utils/helper";
// import CookieConsent from "react-cookie-consent";

function MyApp({ Component, pageProps }) {
  return (
    <GEOProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <Component {...pageProps} />
        </SnackbarProvider>
      </ThemeProvider>
    </GEOProvider>
  );
}

export default MyApp;

import Router from "next/router";
import { createContext, useEffect, useState } from "react";
import { pushParamsToUrl } from "./helper";

export const DARK = "dark";
export const LIGHT = "light";

export const DEFAULT_NUMBER = "DEFAULT_NUMBER";
export const ENTERED_NUMBER = "ENTERED_NUMBER";

export const INCORRECT = "INCORRECT";
export const HAS_PROBLEM = "HAS_PROBLEM";

export const SELECTED_HIGHLIGHTED = "SELECTED_HIGHLIGHTED";
export const ROW_COLUMN_BOX_HIGHLIGHTED = "ROW_COLUMN_BOX_HIGHLIGHTED";
export const SAME_NUMBER_HIGHLIGHTED = "SAME_NUMBER_HIGHLIGHTED";
export const ThemeContext = createContext();
export function ThemeProvider(props) {
  const [state, setState] = useState(DARK);
  const [isMounted, toggleMounted] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("theme")) {
      setState(localStorage.getItem("theme"));
    }
    toggleMounted(true);
  }, []);
  useEffect(() => {
    localStorage.setItem("theme", state);
    document.body.className = state;
    if (isMounted) {
      pushParamsToUrl({ theme_mode: state });
    }
  }, [state]);

  return (
    <ThemeContext.Provider value={{ state, setState }}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export const colors = {
  [DARK]: {
    [INCORRECT]: "#e55c6c",
    [SELECTED_HIGHLIGHTED]: "#375d81",
    [ENTERED_NUMBER]: "#6eb6ff",
    [HAS_PROBLEM]: "#c00e2d5c",
    [ROW_COLUMN_BOX_HIGHLIGHTED]: "#192b3c70",
    [SAME_NUMBER_HIGHLIGHTED]: "#375d8173",
    [DEFAULT_NUMBER]: "#eaeef3",
  },
  [LIGHT]: {
    [INCORRECT]: "#e55c6c",
    [SELECTED_HIGHLIGHTED]: "#bbdefb",
    [ENTERED_NUMBER]: "#0272e3",
    [HAS_PROBLEM]: "#f7cfd6",
    [ROW_COLUMN_BOX_HIGHLIGHTED]: "#e2ebf3",
    [SAME_NUMBER_HIGHLIGHTED]: "#c3d7ea",
    [DEFAULT_NUMBER]: "#344861",
  },
};
